.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;

  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  --lv1-color: #fff;

  color: var(--lv1-color);
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0) translateY(-12px);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -18px;
  animation-delay: -0.32s;
}

.loader:after {
  left: 18px;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 12px 0 -1.3em;
  }
  40% {
    box-shadow: 0 12px 0 0;
  }
}
