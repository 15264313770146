.button {
  --hover-bg: #3f51b5;

  z-index: 0;
  position: relative;
  overflow: hidden;
}

.button[disabled]::before {
  display: none;
}

.button[data-state='loading']::before {
  display: none;
}

.button::before {
  content: '';
  z-index: -1;
  position: absolute;
  left: -25%;
  width: 20%;
  height: 100%;
  border-radius: 50%;
  background: var(--hover-bg);
  -webkit-transform-origin: -15% 50%;
  transform-origin: -15% 50%;
  -webkit-transform: scale3d(1, 2, 1);
  transform: scale3d(1, 2, 1);
  -webkit-transition:
    -webkit-transform 0.3s,
    opacity 0.3s;
  transition:
    transform 0.3s,
    opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
}

.button:hover::before {
  -webkit-transform: scale3d(9, 9, 1);
  transform: scale3d(9, 9, 1);
}
