.bn-mantine {
  --mantine-scale: 1;
  --mantine-cursor-type: default;
  --mantine-color-scheme: light dark;
  --mantine-webkit-font-smoothing: antialiased;
  --mantine-moz-font-smoothing: grayscale;
  --mantine-color-white: #fff;
  --mantine-color-black: #000;
  --mantine-line-height: 1.55;
  --mantine-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --mantine-font-family-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco,
    Consolas, Liberation Mono, Courier New, monospace;
  --mantine-font-family-headings: -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --mantine-heading-font-weight: 700;
  --mantine-heading-text-wrap: wrap;
  --mantine-radius-default: calc(0.25rem * var(--mantine-scale));
  --mantine-primary-color-filled: var(--mantine-color-blue-filled);
  --mantine-primary-color-filled-hover: var(--mantine-color-blue-filled-hover);
  --mantine-primary-color-light: var(--mantine-color-blue-light);
  --mantine-primary-color-light-hover: var(--mantine-color-blue-light-hover);
  --mantine-primary-color-light-color: var(--mantine-color-blue-light-color);
  --mantine-breakpoint-xs: 36em;
  --mantine-breakpoint-sm: 48em;
  --mantine-breakpoint-md: 62em;
  --mantine-breakpoint-lg: 75em;
  --mantine-breakpoint-xl: 88em;
  --mantine-spacing-xs: calc(0.625rem * var(--mantine-scale));
  --mantine-spacing-sm: calc(0.75rem * var(--mantine-scale));
  --mantine-spacing-md: calc(1rem * var(--mantine-scale));
  --mantine-spacing-lg: calc(1.25rem * var(--mantine-scale));
  --mantine-spacing-xl: calc(2rem * var(--mantine-scale));
  --mantine-font-size-xs: calc(0.75rem * var(--mantine-scale));
  --mantine-font-size-sm: calc(0.875rem * var(--mantine-scale));
  --mantine-font-size-md: calc(1rem * var(--mantine-scale));
  --mantine-font-size-lg: calc(1.125rem * var(--mantine-scale));
  --mantine-font-size-xl: calc(1.25rem * var(--mantine-scale));
  --mantine-line-height-xs: 1.4;
  --mantine-line-height-sm: 1.45;
  --mantine-line-height-md: 1.55;
  --mantine-line-height-lg: 1.6;
  --mantine-line-height-xl: 1.65;
  --mantine-shadow-xs: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    0 calc(0.0625rem * var(--mantine-scale))
      calc(0.125rem * var(--mantine-scale)) rgba(0, 0, 0, 0.1);
  --mantine-shadow-sm: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(0.625rem * var(--mantine-scale))
      calc(0.9375rem * var(--mantine-scale))
      calc(-0.3125rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(0.4375rem * var(--mantine-scale))
      calc(0.4375rem * var(--mantine-scale))
      calc(-0.3125rem * var(--mantine-scale));
  --mantine-shadow-md: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(1.25rem * var(--mantine-scale))
      calc(1.5625rem * var(--mantine-scale))
      calc(-0.3125rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(0.625rem * var(--mantine-scale))
      calc(0.625rem * var(--mantine-scale))
      calc(-0.3125rem * var(--mantine-scale));
  --mantine-shadow-lg: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(1.75rem * var(--mantine-scale))
      calc(1.4375rem * var(--mantine-scale))
      calc(-0.4375rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(0.75rem * var(--mantine-scale))
      calc(0.75rem * var(--mantine-scale))
      calc(-0.4375rem * var(--mantine-scale));
  --mantine-shadow-xl: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(2.25rem * var(--mantine-scale))
      calc(1.75rem * var(--mantine-scale))
      calc(-0.4375rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(1.0625rem * var(--mantine-scale))
      calc(1.0625rem * var(--mantine-scale))
      calc(-0.4375rem * var(--mantine-scale));
  --mantine-radius-xs: calc(0.125rem * var(--mantine-scale));
  --mantine-radius-sm: calc(0.25rem * var(--mantine-scale));
  --mantine-radius-md: calc(0.5rem * var(--mantine-scale));
  --mantine-radius-lg: calc(1rem * var(--mantine-scale));
  --mantine-radius-xl: calc(2rem * var(--mantine-scale));
  --mantine-primary-color-0: var(--mantine-color-blue-0);
  --mantine-primary-color-1: var(--mantine-color-blue-1);
  --mantine-primary-color-2: var(--mantine-color-blue-2);
  --mantine-primary-color-3: var(--mantine-color-blue-3);
  --mantine-primary-color-4: var(--mantine-color-blue-4);
  --mantine-primary-color-5: var(--mantine-color-blue-5);
  --mantine-primary-color-6: var(--mantine-color-blue-6);
  --mantine-primary-color-7: var(--mantine-color-blue-7);
  --mantine-primary-color-8: var(--mantine-color-blue-8);
  --mantine-primary-color-9: var(--mantine-color-blue-9);
  --mantine-color-dark-0: #c9c9c9;
  --mantine-color-dark-1: #b8b8b8;
  --mantine-color-dark-2: #828282;
  --mantine-color-dark-3: #696969;
  --mantine-color-dark-4: #424242;
  --mantine-color-dark-5: #3b3b3b;
  --mantine-color-dark-6: #2e2e2e;
  --mantine-color-dark-7: #242424;
  --mantine-color-dark-8: #1f1f1f;
  --mantine-color-dark-9: #141414;
  --mantine-color-gray-0: #f8f9fa;
  --mantine-color-gray-1: #f1f3f5;
  --mantine-color-gray-2: #e9ecef;
  --mantine-color-gray-3: #dee2e6;
  --mantine-color-gray-4: #ced4da;
  --mantine-color-gray-5: #adb5bd;
  --mantine-color-gray-6: #868e96;
  --mantine-color-gray-7: #495057;
  --mantine-color-gray-8: #343a40;
  --mantine-color-gray-9: #212529;
  --mantine-color-red-0: #fff5f5;
  --mantine-color-red-1: #ffe3e3;
  --mantine-color-red-2: #ffc9c9;
  --mantine-color-red-3: #ffa8a8;
  --mantine-color-red-4: #ff8787;
  --mantine-color-red-5: #ff6b6b;
  --mantine-color-red-6: #fa5252;
  --mantine-color-red-7: #f03e3e;
  --mantine-color-red-8: #e03131;
  --mantine-color-red-9: #c92a2a;
  --mantine-color-pink-0: #fff0f6;
  --mantine-color-pink-1: #ffdeeb;
  --mantine-color-pink-2: #fcc2d7;
  --mantine-color-pink-3: #faa2c1;
  --mantine-color-pink-4: #f783ac;
  --mantine-color-pink-5: #f06595;
  --mantine-color-pink-6: #e64980;
  --mantine-color-pink-7: #d6336c;
  --mantine-color-pink-8: #c2255c;
  --mantine-color-pink-9: #a61e4d;
  --mantine-color-grape-0: #f8f0fc;
  --mantine-color-grape-1: #f3d9fa;
  --mantine-color-grape-2: #eebefa;
  --mantine-color-grape-3: #e599f7;
  --mantine-color-grape-4: #da77f2;
  --mantine-color-grape-5: #cc5de8;
  --mantine-color-grape-6: #be4bdb;
  --mantine-color-grape-7: #ae3ec9;
  --mantine-color-grape-8: #9c36b5;
  --mantine-color-grape-9: #862e9c;
  --mantine-color-violet-0: #f3f0ff;
  --mantine-color-violet-1: #e5dbff;
  --mantine-color-violet-2: #d0bfff;
  --mantine-color-violet-3: #b197fc;
  --mantine-color-violet-4: #9775fa;
  --mantine-color-violet-5: #845ef7;
  --mantine-color-violet-6: #7950f2;
  --mantine-color-violet-7: #7048e8;
  --mantine-color-violet-8: #6741d9;
  --mantine-color-violet-9: #5f3dc4;
  --mantine-color-indigo-0: #edf2ff;
  --mantine-color-indigo-1: #dbe4ff;
  --mantine-color-indigo-2: #bac8ff;
  --mantine-color-indigo-3: #91a7ff;
  --mantine-color-indigo-4: #748ffc;
  --mantine-color-indigo-5: #5c7cfa;
  --mantine-color-indigo-6: #4c6ef5;
  --mantine-color-indigo-7: #4263eb;
  --mantine-color-indigo-8: #3b5bdb;
  --mantine-color-indigo-9: #364fc7;
  --mantine-color-blue-0: #e7f5ff;
  --mantine-color-blue-1: #d0ebff;
  --mantine-color-blue-2: #a5d8ff;
  --mantine-color-blue-3: #74c0fc;
  --mantine-color-blue-4: #4dabf7;
  --mantine-color-blue-5: #339af0;
  --mantine-color-blue-6: #228be6;
  --mantine-color-blue-7: #1c7ed6;
  --mantine-color-blue-8: #1971c2;
  --mantine-color-blue-9: #1864ab;
  --mantine-color-cyan-0: #e3fafc;
  --mantine-color-cyan-1: #c5f6fa;
  --mantine-color-cyan-2: #99e9f2;
  --mantine-color-cyan-3: #66d9e8;
  --mantine-color-cyan-4: #3bc9db;
  --mantine-color-cyan-5: #22b8cf;
  --mantine-color-cyan-6: #15aabf;
  --mantine-color-cyan-7: #1098ad;
  --mantine-color-cyan-8: #0c8599;
  --mantine-color-cyan-9: #0b7285;
  --mantine-color-teal-0: #e6fcf5;
  --mantine-color-teal-1: #c3fae8;
  --mantine-color-teal-2: #96f2d7;
  --mantine-color-teal-3: #63e6be;
  --mantine-color-teal-4: #38d9a9;
  --mantine-color-teal-5: #20c997;
  --mantine-color-teal-6: #12b886;
  --mantine-color-teal-7: #0ca678;
  --mantine-color-teal-8: #099268;
  --mantine-color-teal-9: #087f5b;
  --mantine-color-green-0: #ebfbee;
  --mantine-color-green-1: #d3f9d8;
  --mantine-color-green-2: #b2f2bb;
  --mantine-color-green-3: #8ce99a;
  --mantine-color-green-4: #69db7c;
  --mantine-color-green-5: #51cf66;
  --mantine-color-green-6: #40c057;
  --mantine-color-green-7: #37b24d;
  --mantine-color-green-8: #2f9e44;
  --mantine-color-green-9: #2b8a3e;
  --mantine-color-lime-0: #f4fce3;
  --mantine-color-lime-1: #e9fac8;
  --mantine-color-lime-2: #d8f5a2;
  --mantine-color-lime-3: #c0eb75;
  --mantine-color-lime-4: #a9e34b;
  --mantine-color-lime-5: #94d82d;
  --mantine-color-lime-6: #82c91e;
  --mantine-color-lime-7: #74b816;
  --mantine-color-lime-8: #66a80f;
  --mantine-color-lime-9: #5c940d;
  --mantine-color-yellow-0: #fff9db;
  --mantine-color-yellow-1: #fff3bf;
  --mantine-color-yellow-2: #ffec99;
  --mantine-color-yellow-3: #ffe066;
  --mantine-color-yellow-4: #ffd43b;
  --mantine-color-yellow-5: #fcc419;
  --mantine-color-yellow-6: #fab005;
  --mantine-color-yellow-7: #f59f00;
  --mantine-color-yellow-8: #f08c00;
  --mantine-color-yellow-9: #e67700;
  --mantine-color-orange-0: #fff4e6;
  --mantine-color-orange-1: #ffe8cc;
  --mantine-color-orange-2: #ffd8a8;
  --mantine-color-orange-3: #ffc078;
  --mantine-color-orange-4: #ffa94d;
  --mantine-color-orange-5: #ff922b;
  --mantine-color-orange-6: #fd7e14;
  --mantine-color-orange-7: #f76707;
  --mantine-color-orange-8: #e8590c;
  --mantine-color-orange-9: #d9480f;
  --mantine-h1-font-size: calc(2.125rem * var(--mantine-scale));
  --mantine-h1-line-height: 1.3;
  --mantine-h1-font-weight: 700;
  --mantine-h2-font-size: calc(1.625rem * var(--mantine-scale));
  --mantine-h2-line-height: 1.35;
  --mantine-h2-font-weight: 700;
  --mantine-h3-font-size: calc(1.375rem * var(--mantine-scale));
  --mantine-h3-line-height: 1.4;
  --mantine-h3-font-weight: 700;
  --mantine-h4-font-size: calc(1.125rem * var(--mantine-scale));
  --mantine-h4-line-height: 1.45;
  --mantine-h4-font-weight: 700;
  --mantine-h5-font-size: calc(1rem * var(--mantine-scale));
  --mantine-h5-line-height: 1.5;
  --mantine-h5-font-weight: 700;
  --mantine-h6-font-size: calc(0.875rem * var(--mantine-scale));
  --mantine-h6-line-height: 1.5;
  --mantine-h6-font-weight: 700;
}
.bn-mantine[data-mantine-color-scheme='dark'] {
  --mantine-primary-color-contrast: var(--mantine-color-white);
  --mantine-color-bright: var(--mantine-color-white);
  --mantine-color-text: var(--mantine-color-dark-0);
  --mantine-color-body: var(--mantine-color-dark-7);
  --mantine-color-error: var(--mantine-color-red-8);
  --mantine-color-placeholder: var(--mantine-color-dark-3);
  --mantine-color-anchor: var(--mantine-color-blue-4);
  --mantine-color-default: var(--mantine-color-dark-6);
  --mantine-color-default-hover: var(--mantine-color-dark-5);
  --mantine-color-default-color: var(--mantine-color-white);
  --mantine-color-default-border: var(--mantine-color-dark-4);
  --mantine-color-dimmed: var(--mantine-color-dark-2);
  --mantine-color-dark-text: var(--mantine-color-dark-4);
  --mantine-color-dark-filled: var(--mantine-color-dark-8);
  --mantine-color-dark-filled-hover: var(--mantine-color-dark-9);
  --mantine-color-dark-light: rgba(46, 46, 46, 0.15);
  --mantine-color-dark-light-hover: rgba(46, 46, 46, 0.2);
  --mantine-color-dark-light-color: var(--mantine-color-dark-3);
  --mantine-color-dark-outline: var(--mantine-color-dark-4);
  --mantine-color-dark-outline-hover: rgba(66, 66, 66, 0.05);
  --mantine-color-gray-text: var(--mantine-color-gray-4);
  --mantine-color-gray-filled: var(--mantine-color-gray-8);
  --mantine-color-gray-filled-hover: var(--mantine-color-gray-9);
  --mantine-color-gray-light: rgba(134, 142, 150, 0.15);
  --mantine-color-gray-light-hover: rgba(134, 142, 150, 0.2);
  --mantine-color-gray-light-color: var(--mantine-color-gray-3);
  --mantine-color-gray-outline: var(--mantine-color-gray-4);
  --mantine-color-gray-outline-hover: rgba(206, 212, 218, 0.05);
  --mantine-color-red-text: var(--mantine-color-red-4);
  --mantine-color-red-filled: var(--mantine-color-red-8);
  --mantine-color-red-filled-hover: var(--mantine-color-red-9);
  --mantine-color-red-light: rgba(250, 82, 82, 0.15);
  --mantine-color-red-light-hover: rgba(250, 82, 82, 0.2);
  --mantine-color-red-light-color: var(--mantine-color-red-3);
  --mantine-color-red-outline: var(--mantine-color-red-4);
  --mantine-color-red-outline-hover: rgba(255, 135, 135, 0.05);
  --mantine-color-pink-text: var(--mantine-color-pink-4);
  --mantine-color-pink-filled: var(--mantine-color-pink-8);
  --mantine-color-pink-filled-hover: var(--mantine-color-pink-9);
  --mantine-color-pink-light: rgba(230, 73, 128, 0.15);
  --mantine-color-pink-light-hover: rgba(230, 73, 128, 0.2);
  --mantine-color-pink-light-color: var(--mantine-color-pink-3);
  --mantine-color-pink-outline: var(--mantine-color-pink-4);
  --mantine-color-pink-outline-hover: rgba(247, 131, 172, 0.05);
  --mantine-color-grape-text: var(--mantine-color-grape-4);
  --mantine-color-grape-filled: var(--mantine-color-grape-8);
  --mantine-color-grape-filled-hover: var(--mantine-color-grape-9);
  --mantine-color-grape-light: rgba(190, 75, 219, 0.15);
  --mantine-color-grape-light-hover: rgba(190, 75, 219, 0.2);
  --mantine-color-grape-light-color: var(--mantine-color-grape-3);
  --mantine-color-grape-outline: var(--mantine-color-grape-4);
  --mantine-color-grape-outline-hover: rgba(218, 119, 242, 0.05);
  --mantine-color-violet-text: var(--mantine-color-violet-4);
  --mantine-color-violet-filled: var(--mantine-color-violet-8);
  --mantine-color-violet-filled-hover: var(--mantine-color-violet-9);
  --mantine-color-violet-light: rgba(121, 80, 242, 0.15);
  --mantine-color-violet-light-hover: rgba(121, 80, 242, 0.2);
  --mantine-color-violet-light-color: var(--mantine-color-violet-3);
  --mantine-color-violet-outline: var(--mantine-color-violet-4);
  --mantine-color-violet-outline-hover: rgba(151, 117, 250, 0.05);
  --mantine-color-indigo-text: var(--mantine-color-indigo-4);
  --mantine-color-indigo-filled: var(--mantine-color-indigo-8);
  --mantine-color-indigo-filled-hover: var(--mantine-color-indigo-9);
  --mantine-color-indigo-light: rgba(76, 110, 245, 0.15);
  --mantine-color-indigo-light-hover: rgba(76, 110, 245, 0.2);
  --mantine-color-indigo-light-color: var(--mantine-color-indigo-3);
  --mantine-color-indigo-outline: var(--mantine-color-indigo-4);
  --mantine-color-indigo-outline-hover: rgba(116, 143, 252, 0.05);
  --mantine-color-blue-text: var(--mantine-color-blue-4);
  --mantine-color-blue-filled: var(--mantine-color-blue-8);
  --mantine-color-blue-filled-hover: var(--mantine-color-blue-9);
  --mantine-color-blue-light: rgba(34, 139, 230, 0.15);
  --mantine-color-blue-light-hover: rgba(34, 139, 230, 0.2);
  --mantine-color-blue-light-color: var(--mantine-color-blue-3);
  --mantine-color-blue-outline: var(--mantine-color-blue-4);
  --mantine-color-blue-outline-hover: rgba(77, 171, 247, 0.05);
  --mantine-color-cyan-text: var(--mantine-color-cyan-4);
  --mantine-color-cyan-filled: var(--mantine-color-cyan-8);
  --mantine-color-cyan-filled-hover: var(--mantine-color-cyan-9);
  --mantine-color-cyan-light: rgba(21, 170, 191, 0.15);
  --mantine-color-cyan-light-hover: rgba(21, 170, 191, 0.2);
  --mantine-color-cyan-light-color: var(--mantine-color-cyan-3);
  --mantine-color-cyan-outline: var(--mantine-color-cyan-4);
  --mantine-color-cyan-outline-hover: rgba(59, 201, 219, 0.05);
  --mantine-color-teal-text: var(--mantine-color-teal-4);
  --mantine-color-teal-filled: var(--mantine-color-teal-8);
  --mantine-color-teal-filled-hover: var(--mantine-color-teal-9);
  --mantine-color-teal-light: rgba(18, 184, 134, 0.15);
  --mantine-color-teal-light-hover: rgba(18, 184, 134, 0.2);
  --mantine-color-teal-light-color: var(--mantine-color-teal-3);
  --mantine-color-teal-outline: var(--mantine-color-teal-4);
  --mantine-color-teal-outline-hover: rgba(56, 217, 169, 0.05);
  --mantine-color-green-text: var(--mantine-color-green-4);
  --mantine-color-green-filled: var(--mantine-color-green-8);
  --mantine-color-green-filled-hover: var(--mantine-color-green-9);
  --mantine-color-green-light: rgba(64, 192, 87, 0.15);
  --mantine-color-green-light-hover: rgba(64, 192, 87, 0.2);
  --mantine-color-green-light-color: var(--mantine-color-green-3);
  --mantine-color-green-outline: var(--mantine-color-green-4);
  --mantine-color-green-outline-hover: rgba(105, 219, 124, 0.05);
  --mantine-color-lime-text: var(--mantine-color-lime-4);
  --mantine-color-lime-filled: var(--mantine-color-lime-8);
  --mantine-color-lime-filled-hover: var(--mantine-color-lime-9);
  --mantine-color-lime-light: rgba(130, 201, 30, 0.15);
  --mantine-color-lime-light-hover: rgba(130, 201, 30, 0.2);
  --mantine-color-lime-light-color: var(--mantine-color-lime-3);
  --mantine-color-lime-outline: var(--mantine-color-lime-4);
  --mantine-color-lime-outline-hover: rgba(169, 227, 75, 0.05);
  --mantine-color-yellow-text: var(--mantine-color-yellow-4);
  --mantine-color-yellow-filled: var(--mantine-color-yellow-8);
  --mantine-color-yellow-filled-hover: var(--mantine-color-yellow-9);
  --mantine-color-yellow-light: rgba(250, 176, 5, 0.15);
  --mantine-color-yellow-light-hover: rgba(250, 176, 5, 0.2);
  --mantine-color-yellow-light-color: var(--mantine-color-yellow-3);
  --mantine-color-yellow-outline: var(--mantine-color-yellow-4);
  --mantine-color-yellow-outline-hover: rgba(255, 212, 59, 0.05);
  --mantine-color-orange-text: var(--mantine-color-orange-4);
  --mantine-color-orange-filled: var(--mantine-color-orange-8);
  --mantine-color-orange-filled-hover: var(--mantine-color-orange-9);
  --mantine-color-orange-light: rgba(253, 126, 20, 0.15);
  --mantine-color-orange-light-hover: rgba(253, 126, 20, 0.2);
  --mantine-color-orange-light-color: var(--mantine-color-orange-3);
  --mantine-color-orange-outline: var(--mantine-color-orange-4);
  --mantine-color-orange-outline-hover: rgba(255, 169, 77, 0.05);
}
.bn-mantine[data-mantine-color-scheme='light'] {
  --mantine-primary-color-contrast: var(--mantine-color-white);
  --mantine-color-bright: var(--mantine-color-black);
  --mantine-color-text: #000;
  --mantine-color-body: #fff;
  --mantine-color-error: var(--mantine-color-red-6);
  --mantine-color-placeholder: var(--mantine-color-gray-5);
  --mantine-color-anchor: var(--mantine-color-blue-6);
  --mantine-color-default: var(--mantine-color-white);
  --mantine-color-default-hover: var(--mantine-color-gray-0);
  --mantine-color-default-color: var(--mantine-color-black);
  --mantine-color-default-border: var(--mantine-color-gray-4);
  --mantine-color-dimmed: var(--mantine-color-gray-6);
  --mantine-color-dark-text: var(--mantine-color-dark-filled);
  --mantine-color-dark-filled: var(--mantine-color-dark-6);
  --mantine-color-dark-filled-hover: var(--mantine-color-dark-7);
  --mantine-color-dark-light: rgba(46, 46, 46, 0.1);
  --mantine-color-dark-light-hover: rgba(46, 46, 46, 0.12);
  --mantine-color-dark-light-color: var(--mantine-color-dark-6);
  --mantine-color-dark-outline: var(--mantine-color-dark-6);
  --mantine-color-dark-outline-hover: rgba(46, 46, 46, 0.05);
  --mantine-color-gray-text: var(--mantine-color-gray-filled);
  --mantine-color-gray-filled: var(--mantine-color-gray-6);
  --mantine-color-gray-filled-hover: var(--mantine-color-gray-7);
  --mantine-color-gray-light: rgba(134, 142, 150, 0.1);
  --mantine-color-gray-light-hover: rgba(134, 142, 150, 0.12);
  --mantine-color-gray-light-color: var(--mantine-color-gray-6);
  --mantine-color-gray-outline: var(--mantine-color-gray-6);
  --mantine-color-gray-outline-hover: rgba(134, 142, 150, 0.05);
  --mantine-color-red-text: var(--mantine-color-red-filled);
  --mantine-color-red-filled: var(--mantine-color-red-6);
  --mantine-color-red-filled-hover: var(--mantine-color-red-7);
  --mantine-color-red-light: rgba(250, 82, 82, 0.1);
  --mantine-color-red-light-hover: rgba(250, 82, 82, 0.12);
  --mantine-color-red-light-color: var(--mantine-color-red-6);
  --mantine-color-red-outline: var(--mantine-color-red-6);
  --mantine-color-red-outline-hover: rgba(250, 82, 82, 0.05);
  --mantine-color-pink-text: var(--mantine-color-pink-filled);
  --mantine-color-pink-filled: var(--mantine-color-pink-6);
  --mantine-color-pink-filled-hover: var(--mantine-color-pink-7);
  --mantine-color-pink-light: rgba(230, 73, 128, 0.1);
  --mantine-color-pink-light-hover: rgba(230, 73, 128, 0.12);
  --mantine-color-pink-light-color: var(--mantine-color-pink-6);
  --mantine-color-pink-outline: var(--mantine-color-pink-6);
  --mantine-color-pink-outline-hover: rgba(230, 73, 128, 0.05);
  --mantine-color-grape-text: var(--mantine-color-grape-filled);
  --mantine-color-grape-filled: var(--mantine-color-grape-6);
  --mantine-color-grape-filled-hover: var(--mantine-color-grape-7);
  --mantine-color-grape-light: rgba(190, 75, 219, 0.1);
  --mantine-color-grape-light-hover: rgba(190, 75, 219, 0.12);
  --mantine-color-grape-light-color: var(--mantine-color-grape-6);
  --mantine-color-grape-outline: var(--mantine-color-grape-6);
  --mantine-color-grape-outline-hover: rgba(190, 75, 219, 0.05);
  --mantine-color-violet-text: var(--mantine-color-violet-filled);
  --mantine-color-violet-filled: var(--mantine-color-violet-6);
  --mantine-color-violet-filled-hover: var(--mantine-color-violet-7);
  --mantine-color-violet-light: rgba(121, 80, 242, 0.1);
  --mantine-color-violet-light-hover: rgba(121, 80, 242, 0.12);
  --mantine-color-violet-light-color: var(--mantine-color-violet-6);
  --mantine-color-violet-outline: var(--mantine-color-violet-6);
  --mantine-color-violet-outline-hover: rgba(121, 80, 242, 0.05);
  --mantine-color-indigo-text: var(--mantine-color-indigo-filled);
  --mantine-color-indigo-filled: var(--mantine-color-indigo-6);
  --mantine-color-indigo-filled-hover: var(--mantine-color-indigo-7);
  --mantine-color-indigo-light: rgba(76, 110, 245, 0.1);
  --mantine-color-indigo-light-hover: rgba(76, 110, 245, 0.12);
  --mantine-color-indigo-light-color: var(--mantine-color-indigo-6);
  --mantine-color-indigo-outline: var(--mantine-color-indigo-6);
  --mantine-color-indigo-outline-hover: rgba(76, 110, 245, 0.05);
  --mantine-color-blue-text: var(--mantine-color-blue-filled);
  --mantine-color-blue-filled: var(--mantine-color-blue-6);
  --mantine-color-blue-filled-hover: var(--mantine-color-blue-7);
  --mantine-color-blue-light: rgba(34, 139, 230, 0.1);
  --mantine-color-blue-light-hover: rgba(34, 139, 230, 0.12);
  --mantine-color-blue-light-color: var(--mantine-color-blue-6);
  --mantine-color-blue-outline: var(--mantine-color-blue-6);
  --mantine-color-blue-outline-hover: rgba(34, 139, 230, 0.05);
  --mantine-color-cyan-text: var(--mantine-color-cyan-filled);
  --mantine-color-cyan-filled: var(--mantine-color-cyan-6);
  --mantine-color-cyan-filled-hover: var(--mantine-color-cyan-7);
  --mantine-color-cyan-light: rgba(21, 170, 191, 0.1);
  --mantine-color-cyan-light-hover: rgba(21, 170, 191, 0.12);
  --mantine-color-cyan-light-color: var(--mantine-color-cyan-6);
  --mantine-color-cyan-outline: var(--mantine-color-cyan-6);
  --mantine-color-cyan-outline-hover: rgba(21, 170, 191, 0.05);
  --mantine-color-teal-text: var(--mantine-color-teal-filled);
  --mantine-color-teal-filled: var(--mantine-color-teal-6);
  --mantine-color-teal-filled-hover: var(--mantine-color-teal-7);
  --mantine-color-teal-light: rgba(18, 184, 134, 0.1);
  --mantine-color-teal-light-hover: rgba(18, 184, 134, 0.12);
  --mantine-color-teal-light-color: var(--mantine-color-teal-6);
  --mantine-color-teal-outline: var(--mantine-color-teal-6);
  --mantine-color-teal-outline-hover: rgba(18, 184, 134, 0.05);
  --mantine-color-green-text: var(--mantine-color-green-filled);
  --mantine-color-green-filled: var(--mantine-color-green-6);
  --mantine-color-green-filled-hover: var(--mantine-color-green-7);
  --mantine-color-green-light: rgba(64, 192, 87, 0.1);
  --mantine-color-green-light-hover: rgba(64, 192, 87, 0.12);
  --mantine-color-green-light-color: var(--mantine-color-green-6);
  --mantine-color-green-outline: var(--mantine-color-green-6);
  --mantine-color-green-outline-hover: rgba(64, 192, 87, 0.05);
  --mantine-color-lime-text: var(--mantine-color-lime-filled);
  --mantine-color-lime-filled: var(--mantine-color-lime-6);
  --mantine-color-lime-filled-hover: var(--mantine-color-lime-7);
  --mantine-color-lime-light: rgba(130, 201, 30, 0.1);
  --mantine-color-lime-light-hover: rgba(130, 201, 30, 0.12);
  --mantine-color-lime-light-color: var(--mantine-color-lime-6);
  --mantine-color-lime-outline: var(--mantine-color-lime-6);
  --mantine-color-lime-outline-hover: rgba(130, 201, 30, 0.05);
  --mantine-color-yellow-text: var(--mantine-color-yellow-filled);
  --mantine-color-yellow-filled: var(--mantine-color-yellow-6);
  --mantine-color-yellow-filled-hover: var(--mantine-color-yellow-7);
  --mantine-color-yellow-light: rgba(250, 176, 5, 0.1);
  --mantine-color-yellow-light-hover: rgba(250, 176, 5, 0.12);
  --mantine-color-yellow-light-color: var(--mantine-color-yellow-6);
  --mantine-color-yellow-outline: var(--mantine-color-yellow-6);
  --mantine-color-yellow-outline-hover: rgba(250, 176, 5, 0.05);
  --mantine-color-orange-text: var(--mantine-color-orange-filled);
  --mantine-color-orange-filled: var(--mantine-color-orange-6);
  --mantine-color-orange-filled-hover: var(--mantine-color-orange-7);
  --mantine-color-orange-light: rgba(253, 126, 20, 0.1);
  --mantine-color-orange-light-hover: rgba(253, 126, 20, 0.12);
  --mantine-color-orange-light-color: var(--mantine-color-orange-6);
  --mantine-color-orange-outline: var(--mantine-color-orange-6);
  --mantine-color-orange-outline-hover: rgba(253, 126, 20, 0.05);
}
.bn-mantine[data-mantine-color-scheme='dark'] {
  --mantine-color-scheme: dark;
}
.bn-mantine[data-mantine-color-scheme='light'] {
  --mantine-color-scheme: light;
}

@media (max-width: 35.99375em) {
  .mantine-visible-from-xs {
    display: none !important;
  }
}
@media (min-width: 36em) {
  .mantine-hidden-from-xs {
    display: none !important;
  }
}
@media (max-width: 47.99375em) {
  .mantine-visible-from-sm {
    display: none !important;
  }
}
@media (min-width: 48em) {
  .mantine-hidden-from-sm {
    display: none !important;
  }
}
@media (max-width: 61.99375em) {
  .mantine-visible-from-md {
    display: none !important;
  }
}
@media (min-width: 62em) {
  .mantine-hidden-from-md {
    display: none !important;
  }
}
@media (max-width: 74.99375em) {
  .mantine-visible-from-lg {
    display: none !important;
  }
}
@media (min-width: 75em) {
  .mantine-hidden-from-lg {
    display: none !important;
  }
}
@media (max-width: 87.99375em) {
  .mantine-visible-from-xl {
    display: none !important;
  }
}
@media (min-width: 88em) {
  .mantine-hidden-from-xl {
    display: none !important;
  }
}
